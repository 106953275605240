import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import infoStyle from 'assets/jss/material-kit-pro-react/components/infoStyle.jsx';

function InfoArea({ ...props }) {
	const { classes, title, description, iconColor, vertical, className } = props;
	const iconWrapper = classNames({
		[classes.iconWrapper]: true,
		[classes[iconColor]]: true,
		[classes.iconWrapperVertical]: vertical
	});
	const iconClasses = classNames({
		[classes.icon]: true,
		[classes.iconVertical]: vertical
	});
	const infoAreaClasses = classNames({
		[classes.infoArea]: true,
		[className]: className !== undefined
	});
	let icon = null;
	switch (typeof props.icon) {
		case 'string':
			icon = <img className={iconClasses} src={require(`assets/img/${props.icon}.svg`)} alt="" />;
			break;
		default:
			console.log('nothing');
	}
	return (
		<div className={infoAreaClasses}>
			<div className={iconWrapper}>{icon}</div>
			<div className={classes.descriptionWrapper}>
				<h4 className={classes.title}>{title}</h4>
				<div className={classes.description}>{description}</div>
			</div>
		</div>
	);
}

InfoArea.defaultProps = {
	iconColor: 'gray'
};

InfoArea.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]).isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.node.isRequired,
	iconColor: PropTypes.oneOf([ 'primary', 'warning', 'danger', 'success', 'info', 'rose', 'gray' ]),
	vertical: PropTypes.bool,
	className: PropTypes.string
};

export default withStyles(infoStyle)(InfoArea);
